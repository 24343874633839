import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChalkboard, faDatabase,
   faFingerprint, faLaptopCode, faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Row } from 'react-bootstrap';

class WhoWeAreArea extends React.Component {

    render() { 
        return ( 
          <div className="who-we-are-area pt-110 pb-120">
            <Container>
              <Row>
                <div
                  className="col-lg-5 wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0s"
                >
                  <div className="section-title">
                    <span>Quiénes Somos</span>
                    <h2 className="title">
                      Trabajamos duro en brindar las mejores soluciones
                    </h2>
                  </div>
                  {/* section title */}
                </div>
                <div
                  className="col-lg-6 offset-lg-1 wow fadeInRight"
                  data-wow-duration="1.5s"
                  data-wow-delay="0s"
                >
                  <div className="section-title">
                    <p>
                    NIKSEN SAS es una compañia de tecnología con servicios de automatización TI, desarrollo de software, ofrecemos soluciones de comunicaciones cableadas inalámbricas, video vigilancia, detección de incendio entre otras. Nos enfocamos en realizar actividades y proyectos que aporten valor a su empresa o entidad, estudiamos las necesidades y las oportunidades de mejora que puedan convertirse en un diferenciador en sus objetivos de ofrecer mejores productos/servicios con calidad e innovacion. Somos un gran aliado para descubrir el potencial de la integración de la tecnología como habilitador de oportunidades mientras se enfocan en los metas de su negocio.
                    </p>
                  </div>
                  {/* section title */}
                </div>
              </Row>
              <Row>
                <div
                  className="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
                  data-wow-duration=".5s"
                  data-wow-delay="0s"
                >
                  <div className="what-we-do-item text-center mt-30">
                    <FontAwesomeIcon  icon={faLaptopCode} />
                    <h5 className="title">Soluciones TI</h5>
                    <p>Uso de software y sistemas para realizar tareas y procesos automatizados para el aumento de la productividad</p>
                    <Link to={"case-details"}>
                      <FontAwesomeIcon icon={faLongArrowAltRight}/>
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                <div
                  className="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0s"
                >
                  <div className="what-we-do-item text-center mt-30">
                    <FontAwesomeIcon icon={faFingerprint}  />
                    <h5 className="title">Seguridad Electrónica</h5>
                    <p>Sistemas profesionales de CCTV, Detección de Incendio, Control de Acceso</p>
                    <Link to={"case-details"}>
                      <FontAwesomeIcon icon={faLongArrowAltRight}/>
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                <div
                  className="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="0s"
                >
                  <div className="what-we-do-item text-center mt-30">
                    <FontAwesomeIcon icon={faChalkboard} />
                    <h5 className="title">Desarrollo Web & Móvil</h5>
                    <p>Desarrollo de aplicaciones Web y móvil para la optimización de procesos y la gestión de datos</p>
                    <Link to={"case-details"}>
                      <FontAwesomeIcon icon={faLongArrowAltRight}/>
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                <div
                  className="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0s"
                >
                  <div className="what-we-do-item text-center mt-30">
                    <FontAwesomeIcon icon={faDatabase} />
                    <h5 className="title">Gestión de Datos</h5>
                    <p>Soluciones de adquisición, almacenamiento y análisis de datos (Monitorización, alertas, analítica de datos) </p>
                    <Link to={"case-details"}>
                      <FontAwesomeIcon icon={faLongArrowAltRight}/>
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
            <div className="what-we-are-shape-1">
              <img src="/assets/images/what-we-are-shape-1.png" alt="shape" />
            </div>
            <div className="what-we-are-shape-2">
              <img src="/assets/images/what-we-are-shape-2.png" alt="shape" />
            </div>
          </div>
         );
    }
}
 
export default WhoWeAreArea;