import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from 'react-bootstrap';

class LatestServicesPart extends React.Component {

    render() { 
        return ( 
          <React.Fragment>
          <div className="services-title-area">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <div className="services-title-item text-center">
                    <div className="ring-shape" />
                    <span>El mejor servicio</span>
                    <h3 className="title">Ofrecemos la mejor solución para su negocio</h3>
                  </div> 
                </Col>
              </Row> 
            </Container> 
          </div>
          <div className="latest-services-area">
            <Container>
              <Row className="justify-content-center">
                <div
                  className="col-lg-4 col-md-6 col-sm-9 wow fadeInUp"
                  data-wow-duration=".5s"
                  data-wow-delay="0s"
                >
                  <div className="single-services mt-30 mb-55">
                    <div className="services-thumb">
                      <img src="/assets/images/automTI-370x250.png" alt="" />
                    </div>
                    <div className="services-content">
                      <h4 className="title">Automatización TI</h4>
                      <p>
                        Uso de software y sistemas para realizar tareas y procesos automatizados
                        para el aumento de la productividad, reducción de gastos y tiempos con una
                        mejora en la calidad del servicio
                        
                      </p>
                      <Link to={"services-details"}>
                        Más información
                        <FontAwesomeIcon icon={faLongArrowAltRight} className={`pl-1`}/>
                      </Link>
                    </div>
                  </div>
                  {/* single services */}
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-9 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0s"
                >
                  <div className="single-services mt-30 mb-55">
                    <div className="services-thumb">
                      <img src="assets/images/redes-370x250.jpg" alt="" />
                    </div>
                    <div className="services-content">
                      <h4 className="title">Infraestructura de Red</h4>
                      <p>
                        Proveemos servicios de instalación a todo costo de redes de cableado estructurado, 
                        para datos, electricidad, cableado de sistemas de detección de incendio, sistemas de 
                        video vigilancia, alarmas y otros sistemas similares. 
                        Infraestructura para centro de datos y centros de atención al cliente.
                    
                      </p>
                      <Link to={"services-details"}>
                        Más Información <FontAwesomeIcon icon={faLongArrowAltRight} className={`pl-1`}/>
                      </Link>
                    </div>
                  </div>
                  {/* single services */}
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-9 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="0s"
                >
                  <div className="single-services mt-30 mb-55">
                    <div className="services-thumb">
                      <img src="assets/images/services-4.3.jpg" alt="" />
                    </div>
                    <div className="services-content">
                      <h4 className="title">Análisis de Datos</h4>
                      <p>
                      Soluciones para la adquisición, almacenamiento y análisis de datos 
                        (monitorización, alertas, analítica, predicción) accesible en la nube y con app
                        móvil con desarrollo de tableros de acuerdo a las necesidades de los usuarios. 
                      </p>
                      <Link to={"services-details"}>
                        Más Información <FontAwesomeIcon icon={faLongArrowAltRight} className={`pl-1`}/>
                      </Link>
                    </div>
                  </div>
                  {/* single services */}
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-9 wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0s"
                >
                  <div className="single-services mt-30 mb-55">
                    <div className="services-thumb">
                      <img src="/assets/images/cctv-370x250.png" alt="" />
                    </div>
                    <div className="services-content">
                      <h4 className="title">Soluciones de video vigilancia</h4>
                      <p>
                        Instalación y mantenimiento de sistemas de alarma de intrusión y sistemas
                        de video vigilancia
                         
        
                      </p>
                      <Link to={"services-details"}>
                        Read More <FontAwesomeIcon icon={faLongArrowAltRight} className={`pl-1`}/>
                      </Link>
                    </div>
                  </div>
                  {/* single services */}
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-9 wow fadeInUp"
                  data-wow-duration="2.5s"
                  data-wow-delay="0s"
                >
                  <div className="single-services mt-30 mb-55">
                    <div className="services-thumb">
                      <img src="/assets/images/firedetect-370x250.png" alt="" />
                    </div>
                    <div className="services-content">
                      <h4 className="title">Soluciones de detección de incendio</h4>
                      <p>
                        Venta e instalación de sistemas profesionales de detección de incendio
                      </p>
                      <Link to={"services-details"}>
                        Más información <FontAwesomeIcon icon={faLongArrowAltRight} className={`pl-1`}/>
                      </Link>
                    </div>
                  </div>
                  {/* single services */}
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-9 wow fadeInUp"
                  data-wow-duration="3s"
                  data-wow-delay="0s"
                >
                  <div className="single-services mt-30 mb-55">
                    <div className="services-thumb">
                      <img src="/assets/images/services-4.6.jpg" alt="" />
                    </div>
                    <div className="services-content">
                      <h4 className="title">Desarrollo de Software</h4>
                      <p>
                        Desarrollo de software, aplicaciones web y móvil para la optimización de 
                        procesos y gestión de datos.
                      </p>
                      <Link to={"services-details"}>
                        Read More <FontAwesomeIcon icon={faLongArrowAltRight} className={`pl-1`}/>
                      </Link>
                    </div>
                  </div>
                  {/* single services */}
                </div>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
          </React.Fragment>
         );
    }
}
 
export default LatestServicesPart;